import React, { useCallback, useEffect, useState } from "react";
import { InputAdornment, Toolbar, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import Controls from "../../../components/controls/Controls";
import Loader from "../../../components/Loader";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageHeader from "../../../components/PageHeader";
import PageMainContent from "../../../components/PageMainContent";
import useTable from "../../../components/UseTable";
import { routePaths } from "../../../constants/RoutePaths";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { commonApiActionCreator } from "../../../redux-state/actions";

const allBreadcrumbsLinks = [
  {
    link: routePaths.CHARGERFIRMWAREDETAILS,
    title: "Chargers",
  },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "0px solid slategrey",
    },
  },
}));

const headCells = [
  { id: "chargerId", label: "Charger ID" },
  { id: "companyName", label: "Company Name" },
  { id: "depotName", label: "Depot Name" },
  { id: "operation", label: "Operation" },
  {
    id: "performedAt",
    label: "Performed At",
    type: "date",
    render: (row, col) => row[col]?.replace("GMT", "UTC"),
  },
  { id: "performedBy", label: "Performed By" },
];

export default function ChargerHistory() {
  const [loading, setLoading] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [data, setData] = useState([]);
  const classes = useStyles();
  const { GET_CHARGERS_HISTORY } = indexedEndPoints;
  const [companiesData, setCompaniesData] = useState([]);
  const [companiesDataLoading, setCompaniesDataLoading] = useState(false);

  const dispatch = useDispatch();
  const { saveAllCompaniesV2Global } = bindActionCreators(
    commonApiActionCreator,
    dispatch
  );
  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );

  useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
    getCompaniesRawData();
  }, [isCompaniesLoaded]);

  const getCompaniesRawData = async () => {
    setCompaniesDataLoading(true);
    let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));
    if (tempAllCompaniesV2.length !== 0) setCompaniesData(tempAllCompaniesV2);
    setCompaniesDataLoading(false);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      setLoading(true);
      const response = await apiProvider.getAll(`${GET_CHARGERS_HISTORY}`);
      setLoading(false);
      const data = response?.data?.map((row) => {
        let matchedCompany = companiesData?.find(
          (item) => item?.companyId === row?.companyId
        );
        let result = matchedCompany
          ? {
              ...row,
              companyName: matchedCompany.companyName,
              depotName: matchedCompany.depotsList?.find(
                (item) => item?.depotId === row?.depotId
              )?.depotName,
            }
          : { ...row };
        return result;
      });
      setData(data);
    };
    companiesData?.length && fetchHistory();
  }, [companiesData]);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target.value?.trim() === "") return items;
        else
          return items.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };

  const {
    tableContainer: TableContainer,
    tableHead: TableHead,
    tableBody: TableBody,
    tablePagination: TablePagination,
  } = useTable(data, headCells, filterFn);

  const ToolbarMemoised = useCallback(
    ({ handleSearch, classes }) => {
      return (
        <Toolbar className="table_toolbar">
          <Controls.Input
            label={"Search"}
            className="searchInput"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
          <Tooltip title={"Shows operations performed through Ops Console"}>
            <InfoIcon color="primary" />
          </Tooltip>
        </Toolbar>
      );
    },
    [data]
  );

  return (
    <>
      <OpsBreadcrumb
        AllBreadcrumbsLinks={allBreadcrumbsLinks}
        title="Charger History"
      />
      <PageHeader title="Charger History" />
      <PageMainContent>
        <ToolbarMemoised handleSearch={handleSearch} classes={classes} />
        <Loader isLoading={loading || companiesDataLoading} />
        <TableContainer>
          <TableHead />
          {TableBody}
        </TableContainer>
        {TablePagination}
      </PageMainContent>
    </>
  );
}
