import React from "react";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import PageHeader from "../../components/PageHeader";
import { Tab, Chip, Badge } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import NewCommonFilterDrawer from "../../components/NewSideFIlterDrawer";
import VehicleFilter from "./vehicleFilter";
import TuneIcon from "@mui/icons-material/Tune";
import Controls from "../../components/controls/Controls";
import ToastMessage from "../../components/ToastMessage";
import "./vehicleDiscovery.scss";

import Insights from "./insights";
import AllSessions from "./all-sessions";
import DateRangePicker from "./datepicker";
import {
  VehiclesDiscoveryProvider,
  useVehiclesDiscovery,
} from "./VehiclesDiscoveryContext";
import format from "date-fns/format";
import { useHistory } from "react-router-dom";

const VehicleDiscoveryPage = () => {
  const allTabs = ["Insights", "All Sessions"];
  const history = useHistory();
  const [currentTab, setCurrentTab] = React.useState("Insights");
  const {
    fromDate,
    toDate,
    DrawerOC,
    vehicleDiscoveryData,
    toggleDrawer,
    applyFilter,
    allAccounts,
    allMakes,
    allPowerType,
    allDepotType,
    countryCodes,
    toast,
    setToast,
    isFiltered,
    excludeDepotsWithNoVehicles,
    allHomeChargingFlag,
    excludeNoEnergyDeliveredSessions,
    excludeShortSessions,
  } = useVehiclesDiscovery();
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const filter = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllCountryCode,
    tempAllDepotType,
    tempFromDate,
    tempToDate,
    tempExcludeDepotsWithNoVehicles,
    tempAllHomeChargingFlag,
    tempExcludeNoEnergyDeliveredSessions,
    tempExcludeShortSessions
  ) => {
    const filterObj = createFilterPayload(
      tempAllAccounts,
      tempAllMakeModel,
      tempAllPowerType,
      tempAllCountryCode,
      tempAllDepotType,
      tempFromDate,
      tempToDate,
      tempExcludeDepotsWithNoVehicles,
      tempAllHomeChargingFlag,
      tempExcludeNoEnergyDeliveredSessions,
      tempExcludeShortSessions
    );
    toggleDrawer(false, "", {});
  };
  const createFilterPayload = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllCountryCode,
    tempAllDepotType,
    tempFromDate,
    tempToDate,
    tempExcludeDepotsWithNoVehicles,
    tempAllHomeChargingFlag,
    tempExcludeNoEnergyDeliveredSessions,
    tempExcludeShortSessions
  ) => {
    let selectedAccFilters = [];
    let selectedModelFilters = [];
    let selectedPowerType = [];
    let selectedCountryCode = [];
    let selectedDepotType = [];
    let selectedHomeChargingFlag = [];
    let payload = {
      fromDate: format(new Date(tempFromDate), "yyyy-MM-dd"),
      toDate: format(new Date(tempToDate), "yyyy-MM-dd"),
    };
    if (tempAllAccounts[0].children.length) {
      selectedAccFilters = tempAllAccounts[0].children.reduce((acc, cur) => {
        const selectedChildElements = cur.depotsList.reduce((accCh, curCh) => {
          if (curCh.checked) {
            accCh.push(curCh.depotId);
          }
          return accCh;
        }, []);
        acc.push(...selectedChildElements);
        return acc;
      }, []);
    }
    if (tempAllMakeModel[0].children.length) {
      selectedModelFilters = tempAllMakeModel[0].checked
        ? []
        : tempAllMakeModel[0].children.reduce((acc, cur) => {
            const selectedChildElements = cur.chargers.reduce(
              (accCh, curCh) => {
                if (curCh?.soldAs) {
                  curCh?.soldAs?.map((single) => {
                    if (single.checked) {
                      accCh.push(single?.modelId);
                    }
                  });
                }
                return accCh;
              },
              []
            );
            acc.push(...selectedChildElements);
            return acc;
          }, []);
    }
    if (tempAllPowerType[0].children.length) {
      selectedPowerType = tempAllPowerType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    if (tempAllDepotType[0].children.length) {
      selectedDepotType = tempAllDepotType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    if (tempAllCountryCode[0].children.length) {
      selectedCountryCode = tempAllCountryCode[0].children.reduce(
        (codes, countryCodess) => {
          const selectedChildElements = countryCodess.children.reduce(
            (CntryCode, curCh) => {
              if (curCh.checked) {
                CntryCode.push(curCh.value);
              }
              return CntryCode;
            },
            []
          );
          codes.push(...selectedChildElements);
          return codes;
        },
        []
      );
    }
    if (tempAllHomeChargingFlag[0].children.length) {
      selectedHomeChargingFlag = tempAllHomeChargingFlag[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.name);
          }
          return acc;
        },
        []
      );
    }

    if (selectedCountryCode.length)
      payload = { ...payload, countryCode: [...selectedCountryCode] };
    if (selectedAccFilters.length)
      payload = { ...payload, depotId: [...selectedAccFilters] };
    if (selectedModelFilters.length)
      payload = { ...payload, soldAsIdList: [...selectedModelFilters] };
    if (selectedPowerType.length)
      payload = { ...payload, powerType: [...selectedPowerType] };
    if (selectedDepotType.length)
      payload = { ...payload, depotType: [...selectedDepotType] };
    if (tempExcludeDepotsWithNoVehicles)
      payload.excludeDepotsWithNoVehicles = tempExcludeDepotsWithNoVehicles;
    if (selectedHomeChargingFlag.length === 1)
      payload = {
        ...payload,
        isHomeChargingDepot: selectedHomeChargingFlag[0] === "True",
      };
    if (tempExcludeNoEnergyDeliveredSessions)
      payload.excludeNoEnergyDeliveredSessions =
        tempExcludeNoEnergyDeliveredSessions;
    if (tempExcludeShortSessions)
      payload.excludeShortSessions = tempExcludeShortSessions;

      let temp = payload;
      const res = Object.keys(temp)
        .map(
          (key) =>
            `${key}=${
              Array.isArray(temp[key]) ? temp[key].join(",") : temp[key]
            }`
        )
        .join("&");
      history.replace(`/vehicles?${res}`);
    return payload;
  };

  return (
    <>
      <OpsBreadcrumb title="Vehicle Discovery" />
      <div className="wrap">
        <PageHeader title={"Vehicle Discovery"} />
        <div className="lwrap">
          <DateRangePicker />
          <Badge
            color="primary"
            variant="dot"
            invisible={isFiltered ? false : true}
          >
            <Controls.Button
              text="Filter"
              variant="outlined"
              startIcon={<TuneIcon className="filterIcon" />}
              onClick={() => {
                toggleDrawer(true, "FILTER", {});
              }}
              id="vehicleDiscoveryFilter"
              className="filterButton"
            />
          </Badge>
        </div>
      </div>
      <TabContext value={currentTab}>
        <TabList
          onChange={handleChange}
          aria-label="lab API tabs example"
          sx={{
            borderBottom: "1px solid #d6d7de",
            "& .MuiTabs-indicator": {
              backgroundColor: "#1890ff",
            },
          }}
        >
          {allTabs?.map((single) => {
            return <Tab key={single} label={single} value={single} />;
          })}
          <Chip
            label={vehicleDiscoveryData?.length || 0}
            className="chipStyle"
            size="small"
          />
        </TabList>
        {allTabs?.map((single) => {
          return (
            <TabPanel key={single} value={single}>
              {/** Placeholder Tab */}
              {single === "Insights" && <Insights />}
              {single === "All Sessions" && <AllSessions />}
            </TabPanel>
          );
        })}
      </TabContext>
      <NewCommonFilterDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDrawer}>
        <VehicleFilter
          applyFilter={applyFilter}
          setFilter={filter}
          allAccounts={allAccounts}
          allMakes={allMakes}
          allPowerType={allPowerType}
          allDepotType={allDepotType}
          countryCode={countryCodes}
          fromDate={fromDate}
          toDate={toDate}
          excludeDepotsWithNoVehicles={excludeDepotsWithNoVehicles}
          allHomeChargingFlag={allHomeChargingFlag}
          excludeNoEnergyDeliveredSessions={excludeNoEnergyDeliveredSessions}
          excludeShortSessions={excludeShortSessions}
        />
      </NewCommonFilterDrawer>
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
};

const VehicleDiscovery = () => {
  return (
    <div>
      <VehiclesDiscoveryProvider>
        <VehicleDiscoveryPage />
      </VehiclesDiscoveryProvider>
    </div>
  );
};

VehicleDiscovery.displayName = "VehicleDiscovery";

export default VehicleDiscovery;
