import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";

const {
  CHARGER_PROFILE,
  GET_CHARGERS_FIRMWARE_DETAILS,
  GET_FIRMWARE_DETAILS_COUNT,
  GET_CHARGERS_PORTS_COUNTS,
  DEPOT_CHARGERS,
  GET_ALL_DEMAND_RESPONSE_PROGRAMS,
  CHARGER_CREDENTIAL,
} = indexedEndPoints;

export const getChargerProfile = async (id) =>
  await apiProvider.getAll(`${CHARGER_PROFILE}/${id}/profiles`);

export const getOCPPSettingsByCharger = async (depotID, chargeID) =>
  await apiProvider.getAll(
    `${GET_CHARGERS_FIRMWARE_DETAILS}/ocpp-lastKnown/${depotID}/${chargeID}`
  );

export const getChargerPortCells = async (id) =>
  await apiProvider.getAll(`${CHARGER_PROFILE}/${id}/cells`);

export const getChargersAndFirmware = async (body) =>
  body
    ? await apiProvider.post(GET_CHARGERS_FIRMWARE_DETAILS, body)
    : await apiProvider.post(GET_CHARGERS_FIRMWARE_DETAILS, {});

export const getFirmwareCountOnCHD = async (body) =>
  body
    ? await apiProvider.post(GET_FIRMWARE_DETAILS_COUNT, body)
    : await apiProvider.post(GET_FIRMWARE_DETAILS_COUNT, {});

export const getCommissionedChargerAndPortCount = async (body) => {
  return await apiProvider.post(GET_CHARGERS_PORTS_COUNTS, body);
};

export const getChargersListForDepot = async (depotId, bool = false) => {
  return await apiProvider.getAll(
    `${DEPOT_CHARGERS}/${depotId}?allChargers=${bool}`
  );
};

export const getAllDRPrograms = async () =>
  await apiProvider.getAll(GET_ALL_DEMAND_RESPONSE_PROGRAMS);

export const getCredentialByCharger = async (depotId, objectId, chargerId) =>
  await apiProvider.getAll(
    `${CHARGER_CREDENTIAL}/${depotId}/${objectId}/${chargerId}`
  );

export default {
  getChargersAndFirmware,
  getCommissionedChargerAndPortCount,
};
